
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
























































































































































































































































































































































































































































.intro {
  position: absolute;
  z-index: 2; // higher than TvChannels
  top: 0;
  left: 0;
  width: 100;
  height: $parallax-intro-height;
  color: $white;
  text-align: center;
  pointer-events: none;

  &.active {
    pointer-events: auto;
  }
}

.intro__content {
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.intro__title {
  margin-bottom: 3.6rem;
  font-weight: 400;
  will-change: opacity, transform;

  &::after {
    @include center-xy;

    z-index: -1;
    content: '';
    width: 100vw;
    height: 120vh;
    background: rgba($black, 0.6);
    will-change: opacity; // Opacity changes on parent element. Adding will-change fixes performance issues on older iOs devices.
  }
}

.intro__already-customer {
  position: absolute;
  bottom: 9rem;
  color: inherit;
  will-change: opacity, transform;

  span {
    display: block; // else transform won't work
  }
}

.scroll-indicator {
  position: fixed;
  bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  will-change: opacity, transform;

  p {
    margin: 0 0 1rem;
  }
}

[data-animation-fade-in-up] {
  will-change: opacity, transform;
}

.scroll-indicator__svg {
  display: block;
  stroke: none;
  width: auto;
  height: 4rem;
  fill: currentcolor;
}

.intro__canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.dummy-content {
  display: flex;
  align-items: center;
  width: 50%;
  height: 100vh;
  margin: auto;
}
