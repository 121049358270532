
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






























































































































































































































































































@function vwify($pxValueWithoutUnit, $base: 1600) {
  @return #{($pxValueWithoutUnit / $base) * 100}vw;
}

.tv-channels {
  position: relative;
  color: $white;
  margin-bottom: -50vh;
}

.tv-channels__content {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $channels-content-height;
  overflow: hidden; // Prevent overflow caused by title's backdrop
  padding-top: 20vh;

  @include mq(l) {
    width: 100%;
  }
}

.tv-channels__content__title {
  @extend %text-center;

  position: relative;
  max-width: 15em;
  color: $white;
  font-weight: 400;

  &::after {
    @include center-xy;

    z-index: -1;
    content: '';
    width: 150%;
    height: 200%;
    // background: rgba(8, 40, 51, 0.7);
    // filter: blur(109px);
    background: radial-gradient(
      ellipse at center,
      rgba(8, 40, 51, 0.8) 40%,
      transparent 75%
    );
    will-change: opacity; // Opacity changes on parent element. Adding will-change fixes performance issues on older iOs devices.
  }

  @include mq(m) {
    &::after {
      width: 100%;
    }
  }
}

.tv-channels__content__hook {
  @extend %text-center;
  @extend %fw-semi;

  position: relative;
  margin: $spacing;
  font-family: $ff-alt;
  color: $white;

  .icon {
    display: block;
    width: 3rem;
    height: 3rem;
    margin: $spacing auto 0;
    fill: currentColor;
  }

  &::after {
    @include center-x;

    z-index: -1;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ellipse at center,
      rgba(8, 40, 51, 0.9) 0,
      transparent 75%
    );
    will-change: opacity; // Opacity changes on parent element. Adding will-change fixes performance issues on older iOs devices.
  }
}

.tv-channels__bg {
  overflow: hidden; // Prevent x-overflow caused by offsetted items
  padding: $parallax-intro-height 0 30vh;
  margin-top: -$channels-content-height + $channels-thumbnails-offet;

  @include mq(m) {
    padding: $parallax-intro-height $spacing 50vh;
  }
}

.tv-channels__bg__list {
  @extend %list-nostyle;
  --item-per-column: 2;

  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(var(--item-per-column), 1fr);
  grid-template-rows: auto;
  justify-items: center;
  gap: 5rem $spacing;
  padding-bottom: 25vh;
  counter-reset: item;

  @include mq(m) {
    // --item-per-column: 3;
    // gap: 10rem $spacing;
    --item-per-column: 4;
    // gap: 18rem $spacing * 3;
    gap: 8vw 3vw;
  }
}

.tv-channels__bg__item {
  --x: 0;
  --width: #{vwify(150, 360)};
  --ar: 150/112;

  position: relative;
  z-index: 1;
  font-family: $ff-alt;
  will-change: transform;
  width: var(--width);
  transform: translateX(var(--x));

  &.small {
    padding: 1rem;
  }

  @include mq($until: m) {
    &:nth-child(2n + 1) {
      margin-top: -6rem;
    }

    &:nth-child(n + 9) {
      display: none;
    }
  }

  @include mq(m) {
    --width: 100%; // adapts to the column width
    --ar: 16/9;

    &:nth-child(4n + 2),
    &:nth-child(4n + 4) {
      margin-top: -6rem;
    }

    &.medium {
      padding: 1rem;
    }

    &.small {
      padding: 2rem;
    }
  }
}

.tv-channels__bg__item__picture {
  display: block;
  border-radius: 1rem;
  aspect-ratio: var(--ar);
  object-fit: cover;
  box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.25);
}

.tv-channels__bg__item__title {
  font-family: $ff-alt;
  line-height: 1.2;
  margin-top: 5px;
}

.tv-channels__bg__item__link {
  display: flex;
  width: fit-content;
  margin: auto;

  ::v-deep .btn__icon {
    margin-left: $spacing * 0.5;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  @include mq(l) {
    margin-left: 0;
  }
}

.tv-channels__content__title,
.tv-channels__content__link {
  will-change: transform, opacity;
}
