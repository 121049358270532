
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        

































































































































































































































































































































































































































































































































































































































































































// Finetune this value to pinpoint the nice scroll-to-snape balance.
// It does not impact anything visually since slideshow canvas is pinned.
$slide-height: 100vh;
$outro-tolerance: 25vh;

.background-blur-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.discover-universes {
  position: relative;
  height: calc(#{$slide-height} * var(--slides-count, 1) + #{$outro-tolerance});

  > [class*='wrapper'] {
    height: 100%;
  }
}

.discover-universes__sticky {
  position: sticky;
  z-index: 1;
  top: 0;
  overflow: hidden;
  height: 100vh;
}

::v-deep {
  .gl-canvas {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100vh;

    &.is-transitioning {
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.bullets {
  position: fixed;
  z-index: 2; // Higher than slides
  top: 50vh;
  right: $wrapper-spacing-s;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;

  &.is-transitioning {
    position: fixed;
  }

  @include mq(m) {
    right: $wrapper-spacing-m;
  }

  @include mq(xl) {
    right: $wrapper-spacing-xl;
  }
}

.bullets__item {
  width: 1rem;
  height: 1rem;
  background-color: $c-blue-pale;
  border-radius: 100%;
  transition: background-color 250ms $ease-in-out;

  &:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  &.active {
    background-color: $c-pink-medium;
  }
}

.slides__outer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.slides-container {
  display: flex;
  height: 100%;
  color: $white;
}

.slide-ui {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;

  &.is-transitioning {
    position: fixed;
  }

  &.active {
    pointer-events: auto;
  }
}

.slide-ui__inner-wrapper {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.slide-ui__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40vh;

  .btn:not(h2 + .btn) {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  ::v-deep {
    .btn {
      &.loading {
        pointer-events: none;
        opacity: 0.75;
      }
    }
  }

  &:hover {
    ::v-deep {
      h2 + .btn {
        background-color: #6b9900;

        .btn__icon {
          transform: translateX(0.7rem);
        }
      }
    }
  }
}

.slide-ui__title {
  --c-shadow: rgba($black, 0.1);

  // prettier-ignore
  @include fluid(font-size, (s: 50px, l: 110px));

  // prettier-ignore
  @include fluid(margin-top, (s: 0, l: 40px));

  margin-bottom: 34px;
  color: $white;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;

  // prettier-ignore
  text-shadow:
    0 0 20px var(--c-shadow),
    0 0 40px var(--c-shadow),
    0 0 80px var(--c-shadow),
    0 0 160px var(--c-shadow);
}

.discover-universes__extra-tv {
  bottom: 3rem;
  color: $white;
  font-size: 1.5rem;
}

.background-blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.background-blur-container,
.background-blur {
  will-change: opacity;
}

[data-fade-out-down] {
  will-change: transform, opacity;
}
