
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






















































































































































.tv-hub__section {
  &:nth-child(1) {
    z-index: 3;
  }

  &:nth-child(2) {
    z-index: 1;
  }

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    position: relative;
    z-index: 2;
  }
}

.loader {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
}

.loader__content {
  // prettier-ignore
  @include fluid(max-width, (s: 100px, l: 200px));

  transform: translate(-50%, -50%);
}

.tv-home__discover {
  z-index: 1;
  background: $c-light-gray;
}
